<template>
  <div class="search-main">
    <el-row class="search-btn">
      <el-col :span="12">
        <div class="search-title"><i class="el-icon-search"></i> 条件筛选</div>
      </el-col>
      <el-col :span="12" class="btn-main">
       <span >
        <span v-show="isCollapsed" class="arrow" @click="on"><i class="el-icon-arrow-down" ></i></span>
        <span v-show="!isCollapsed" class="arrow" @click="on"><i class="el-icon-arrow-up" ></i></span>
       </span>
        
       <el-button size="small" type="success" @click="search" icon="el-icon-search"  class="btn">查询</el-button>
        <el-button size="small" @click="reset" icon="el-icon-refresh"  type="success" class="btn">重置</el-button>
        <!-- <el-button size="small" @click="tableExport"  class="exportBtn iconfont icon-fenxiang" type="success"  v-if="isExport">导出</el-button> -->
      </el-col>
    </el-row>
    <div class="condition-main" :class="{ 'collapsed': isCollapsed }" ref="contentRef">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchHead",
  props:['isExport'],
  data(){
    return {
      isCollapsed: true,
      isCollapseds:true
    }
  },
  mounted() {
    this.checkOverflow();
    window.addEventListener('resize', this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  updated() {
    this.checkOverflow();
  },
  methods:{
    reset(){
      this.$emit('reset')
    },
    search(){
      this.$emit('search')
    },
    tableExport(){
      this.$emit('tableExport')
    },
    on(){
      this.isCollapsed = !this.isCollapsed;
    },
    checkOverflow() {
      const contentDiv = this.$refs.contentRef;
      // console.log(contentDiv.scrollHeight,'contentDiv');
      this.isCollapsed = contentDiv.scrollHeight > contentDiv.clientHeight;
      this.isCollapseds = contentDiv.scrollHeight > contentDiv.clientHeight;
    },
  }
}
</script>

<style scoped lang="less">
.search-main{
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  .search-btn{
    padding: 5px 20px;
  }
  .search-title{
    position: relative;
    top: 5px;
    font-weight: 600;
  }
}
.btn-main{
  text-align: right;
}
.condition-main{
  border-top: 1px solid #f0f0f0 ;
  padding:15px 20px 0px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.collapsed {
  max-height: 50px; /* Set the maximum height for the content */
}
.on{
  font-size: 12px;
  margin-right: 15px;
}
.on:hover {
  cursor: pointer;
  color: #67c3ff;
}
.arrow{
  font-size: 16px;
  margin-right: 10px;
}
.arrow:hover {
  cursor: pointer;
  color: #67c3ff;
}
.btn{
  background-color: #ffffff;
  color: #008f4d;
}
.btn:hover{
  background-color: #008f4d;
  color:#ffffff
}
.exportBtn{
  background-color: #008f4d;
  font-size: 12px;
}
</style>