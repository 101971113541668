<template>
  <div>
    <div class="head-btn">
   
      <!-- <slot name="title"></slot> -->
      <slot name="tabs"></slot>
      <slot name="search"></slot>
     <el-row>
       <el-col :span="12" v-if="!titleIcon">
         <div class="table-title">{{title}}&nbsp;</div>
       </el-col>
       <el-col :span="12" class="right">
        <slot name="btns"></slot>
       </el-col>
     </el-row>
    </div>
    <el-table
        :data="data"
        :header-cell-style="headerCellStyle"
        :height="height"
        stripe
        border
        highlight-current-row
        @current-change="handleCurrentChanges"
        :row-style="getRowClassName"
        header-row-class-name="custom-header-row"
        @selection-change="handleSelectionChange">
      <slot></slot>
    </el-table>
    <el-pagination
        class='pag-right'
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="10"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "CustomTables",
  props:['data','currentPage','total','title','noimport','noexport','noall','isdel','iscode','isadd','headerCellStyle','height','titleIcon'],
  data(){
    return {
    }
  },
  methods: {
    handleSizeChange(item){
      this.$emit('handleSizeChange', item)
    },
    handleCurrentChange(item){
      this.$emit('handleCurrentChange', item)
    },
    handleCurrentChanges(item){
      this.$emit('handleCurrentChanges', item)
    },
    handleSelectionChange(val){
      this.$emit('selectionChange', val)
    },
    getRowClassName(row, index) {
      return {
      background: row.rowIndex % 2 == 0 ? '#f3fffb' : '#f3f2f7'
    };

    },
  }
}
</script>

<style scoped lang="less">
.pag-right{
  text-align: right;
  margin-top: 20px;
  overflow: auto;
}
.head-btn{
  padding: 10px 20px;
  border: 1px solid #f0f0f0;
  border-bottom:none ;
  //border-top-left-radius: 10px;
  //border-top-right-radius: 10px;
  .table-title{
    font-weight: bolder;
    position: relative;
    top: 6px;
    .iconfont{
      margin-right: 5px;
    }
  }
}
.right{
  display: flex;
  justify-content: right;

  .el-button{
    margin-left:15px ;
  }
}
/deep/.el-pager li.active {
    color: #008f4d;
    cursor: default;
}
/deep/.el-pager li:hover {
    color: #008f4d;
    cursor: default;
}
</style>