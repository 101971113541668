import api from '../httpServer'
import config from '../config'
// import store from '@/store/index'

const monitor ={
    //分页查询设备统计
    getCounts(data) {
        return api.get(`${config.headerUrl}monitor/device/count/getCounts`,data)
    },
    //设备统计分页查询分页查询
    getPageCount(data) {
        return api.get(`${config.headerUrl}monitor/device/count/DeviceCountgetPage`,data)
    },
    //分页查询项目信息
    getPageProjName(data) {
        return api.get(`${config.headerUrl}monitor/device/count/getPageProjName`,data)
    },
      //分页查询项目信息
      getDeviceSelect(data) {
        return api.get(`${config.headerUrl}device/select/getDeviceSelect`,data)
    },
    //分页查询实时监控列表
    getRealListByPage(data) {
        return api.get(`${config.headerUrl}monitor/real/getRealListByPage`,data)
    },
      //查询实时监控设备个数
      getRealCount(data) {
        return api.get(`${config.headerUrl}monitor/real/getRealCount`,data)
    },
    //分页查询远程抓拍列表
       getSnapListByPage(data) {
        return api.get(`${config.headerUrl}monitor/snap/getSnapListByPage`,data)
    },
    //新增远程抓拍
    addSnap(data) {
        return api.post(`${config.headerUrl}monitor/snap/addSnap`,data)
    },
    //删除远程抓拍
    delSnap(data) {
        return api.get(`${config.headerUrl}monitor/snap/delSnap/${data}`,data)
    },
    //分页查询公司报警排行列表
    getCompanyAlarmListByPage(data) {
        return api.get(`${config.headerUrl}monitor/alarm/getCompanyAlarmListByPageFast`,data)
    },
    //分页查询项目报警排行列表
    getProjectAlarmListByPage(data) {
        return api.get(`${config.headerUrl}monitor/alarm/getProjectAlarmListByPage`,data)
    },
    //查询项目设备分类个数
    getProjectDeviceCount(data) {
        return api.get(`${config.headerUrl}monitor/alarm/getProjectDeviceCount/${data}`,data)
    },
    //分页查询项目设备列表
    getProjectDeviceListByPage(data) {
        return api.get(`${config.headerUrl}monitor/alarm/getProjectDeviceListByPage`,data)
    },
    //根据IMEI分页查询告警列表
    getAlarmByPage(data) {
        return api.get(`${config.headerUrl}monitor/alarm/getAlarmByPage`,data)
    },
    //报警处理
    disposeAlarm(data) {
        return api.get(`${config.headerUrl}monitor/alarm/disposeAlarm/${data}`,data)
    },
    //分页查询违停排行列表
    getAlarmListByPage(data) {
        return api.get(`${config.headerUrl}monitor/llegal/getAlarmListByPageFast`,data)
    },
     //分页项目排行列表
     getllegalProject(data) {
        return api.get(`${config.headerUrl}monitor/llegal/getllegalProject`,data)
    },
     //分页查询项目设备列表
     getProjectDeviceCountllegal(data) {
        return api.get(`${config.headerUrl}monitor/llegal/getProjectDeviceCount/${data}`,data)
    },
    //分页查询项目设备列表
    getProjectDeviceListByPagellegal(data) {
        return api.get(`${config.headerUrl}monitor/llegal/getProjectDeviceListByPage`,data)
    },
    //分页查询违章列表
    getPage(data) {
        return api.get(`${config.headerUrl}monitor/llegal/llPage/getPage`,data)
    },
    //分页查询项目列表
    getProjectListByPage(data) {
        return api.get(`${config.headerUrl}monitor/tower/work/getProjectListByPage`,data)
    },
    //分页查询项目设备列表
    getProjectDeviceListByPagework(data) {
        return api.get(`${config.headerUrl}monitor/tower/work/getProjectDeviceListByPage`,data)
    },
    //分页查询工作循环列表（今天 本周 上周 本月）
    getWorkListByPage(data) {
        return api.get(`${config.headerUrl}monitor/tower/work/getWorkListByPage`,data)
    },
    //分页查询设备运行列表
    getDeviceRunListByPage(data) {
        return api.get(`${config.headerUrl}monitor/device/run/getDeviceRunListByPage`,data)
    },
    //分页查询塔机司机
    getPagestower(data) {
        return api.get(`${config.headerUrl}monitor/tower/getPages`,data)
    },
    //分页查询项目列表
    getProjectDeviceCountTower(data) {
        return api.get(`${config.headerUrl}monitor/tower/getProjectListByPage`,data)
    },
    //分页查询驾驶司机
    getTowergetPage(data) {
        return api.get(`${config.headerUrl}monitor/tower/getPage`,data)
    },
    //根据项目类别查询项目个数
    getProjectCount(data) {
        return api.get(`${config.headerUrl}monitor/map/getProjectCount`,data)
    },
    //查询项目下拉框列表
    getProjectMapList(data) {
        return api.get(`${config.headerUrl}monitor/map/getProjectMapList`,data)
    },
    //摄像头查询
    getCameraSelect(data) {
        return api.get(`${config.headerUrl}camera/select/getCameraSelect`,data)
    },
    //分页查询项目列表排行扬尘
    getInfoByPage(data) {
        return api.get(`${config.headerUrl}monitor/device/construction/getInfoByPage`,data)
    },
      //分页查询工作循环列表（今天 本周  本月）
      getInfoListByPage(data) {
        return api.get(`${config.headerUrl}monitor/device/construction/getInfoListByPage`,data)
    },
    //扬尘监控大屏
    getInfoConstruction(data) {
        return api.get(`${config.headerUrl}monitor/device/construction/getInfo`,data)
    },
}

export default monitor