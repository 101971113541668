<template>
    <div class="">
      <div v-if="infoShow">
        {{ info }}
      </div>
      <div class="title" v-if="!iconShow">
        <i class="el-icon-tickets icon" ></i>
        <span>组织机构</span>
      </div>
      <div class="condition-main">
        <div>
            <slot></slot>
          
        </div>
        <div >
            <el-button size="small" type="success" @click="search" icon="el-icon-search" class="btn"></el-button>
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:['iconShow','info','infoShow'],
    name: "SearchLeft",
    methods:{
      reset(){
        this.$emit('reset')
      },
      search(){
        this.$emit('search')
      }
    }
  }
  </script>
  
  <style scoped lang="less">
  
  .condition-main{
    // border-top: 1px solid #f0f0f0 ;
    padding:15px 20px 0px;
    padding-left: 0;
    display: flex;
   align-items: center;
  
  }
.icon{
  margin-right: 10px;
}
.btn{
  background-color: #008f4d;
  display: block;
}
  </style>